figure {
  margin: 0;
  margin-bottom: $spacer-xl;
  padding: $figure-element-padding;
  background: $figure-element-background;
  border: $figure-element-border;
  display: inline-block;
  img {
    margin: 0;
    display: block;
  }
}

figcaption {
  color: $figcaption-color;
  font-size: $figcaption-font-size;
  font-family: $figcaption-font-family;
  background: $figcaption-background;
  line-height: $figcaption-line-height;
  padding: $figcaption-padding;
  display: flex;
  align-items: center;
  .body-text & {
    font-size: #{$figcaption-font-size/$body-text-font-size}em;
  }
}

img {
	max-width: 100%;
	height: auto;
	margin-bottom: $spacer-lg;
}

svg {
	width: auto;
	height: auto;
}

.embedded-video {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

figure.embedded-video-container {
  display: block;
}

iframe {
  border-width: 0;
}