

.member-schools-list {
  .main-block {
    max-width: 1440px;	
  }
  .content-list {
    margin: -1*$spacer-md;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.member-schools-nav {
  ul {
    display: flex;
    justify-content: center;
    list-style: none;
    flex-wrap: wrap;
    max-width: 708px;
    margin: 0 0 $spacer-xl 0;
    padding: 0;
    li {
      padding: 0 $spacer-md;
      margin-bottom: $spacer-md;
      &:not(:last-child) {
        border-right: 1px solid #bbb;
      }
      a {
        text-decoration: none;
        color: #999;
        font-weight: 600;
      }
    }
  }
}

.member-school-contact-info {
  min-width: 0; // https://stackoverflow.com/questions/59137887/word-wrap-not-working-in-flex-item-inside-row-flex-container
  ul {
    margin: 0;
    padding: 0;
    font-size: #{1.3em/$body-text-font-size}em;
    list-style: none;	
    padding-top: 4px;
    flex-basis: 0;
    flex-grow: 1;
    li {
      display: flex;
      align-items: flex-start;
      flex-grow: 1;
      .text {
        overflow-wrap: break-word;
        min-width: 0; //https://stackoverflow.com/questions/59137887/word-wrap-not-working-in-flex-item-inside-row-flex-container
      }
      svg {
        fill: $primary;
        width: $spacer-lg;
        flex-shrink: 0;
        margin-right: $spacer-sm;
        margin-top: $spacer-sm;
      }
    }
  }
}

.member-school-card {
	border: 1px solid $sidebar-box-border-color;
	background-color: $sidebar-box-background;
  margin: $spacer-md;
  padding: $spacer-md;
  width: calc(33.3% - #{2*$spacer-md});
  width: 100%;
  max-width: 450px;
  display: flex;
  .thumbnail-link {
    width: 20%;	
    margin-right: $spacer-md;
    flex-shrink: 0;
    img {
      margin: 0;
      display: block;
      width: 100%;
    }
  }

  h2 {
    margin-bottom: 0;
    margin-top: -2px;
    font-size: #{1.4em/$body-text-font-size}em;
    a {
      color: $link-color;
    }
  }
}

.member-school-page {
  .thumbnail-image {
    float: left;
    max-width: 120px;
    width: 100%;
    margin-right: $spacer-lg;
  }
  .sidebar {
    width: 384px;
    &.wide {
      width: 100%;
    }
    ul.member-school-contact-info {
      margin-bottom: $spacer-lg;
    }
    h4 {
      &:not(:first-child) {
        margin-top: $spacer-lg;
      }
      margin-bottom: $spacer-sm;
    }
  }
}

.member-school-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  .main-block {
    margin-bottom: $spacer-xl;
  }
}

@media (min-width: 980px) {
  .member-school-details {
    display: flex;
    justify-content: center;
    .main-block {
      margin-bottom: 0;
    }
  }
}