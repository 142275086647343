$blog-item-border-bottom: true !default;
$blog-item-border-bottom-width: 1px !default;
$blog-item-border-bottom-color: #ddd !default;
$blog-item-border-bottom-style: solid !default;
$blog-item-padding-top: 10px !default;
$blog-item-padding-bottom: 10px !default;


$blog-item-image-block-max-width: 175px !default;
$blog-item-image-margin-right: 0 !default;
$blog-item-image-margin-top: 5px !default;
$blog-item-image-margin-bottom: 7px !default;
$blog-item-image-block-border-color: #ddd !default;
$blog-item-image-block-border-width: 1px !default;

$blog-item-header-margin-top: 0 !default;

/*added*/

$item-date-created-font-size: $body-text-font-size !default;
$item-date-created-color: #999 !default;
$item-date-created-margin-bottom: 1em !default;
$item-date-created-line-height: 150% !default;
$item-date-created-font-weight: $body-text-font-weight  !default;

$item-read-more-padding-top-bottom: 0px !default;
$item-read-more-padding-left-right: 0px !default;
$item-read-more-border-radius: 5px !default;
$item-read-more-font-weight: 600 !default;
$item-read-more-font-family: "Lato", sans-serif !default;
$item-read-more-font-size: 0.8em !default;
$item-read-more-text-transform: uppercase !default;
$item-read-more-link-text-decoration: none !default;
$item-read-more-link-hover-text-decoration: none !default;
$item-read-more-color: $link-color !default;
$item-read-more-background-color: transparent !default;
$item-read-more-hover-color: $link-hover-color !default;
$item-read-more-hover-background-color: transparent !default;

$taxonomy-list-header-color: $item-date-created-color !default;
$taxonomy-list-font-size: $body-text-font-size !default;
$taxonomy-list-link-text-decoration: none !default;
$taxonomy-list-link-hover-text-decoration: underline !default;
