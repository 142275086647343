/*---------------------------------------------------------------------------------
GENERAL (APPLY TO ALL MEDIA QUERIES)
---------------------------------------------------------------------------------*/

$main-nav-font-weight: 600 !default;
$mobile-menu-threshold: 979px !default;
/*---------------------------------------------------------------------------------
PC NAV ITEMS - ALL LEVELS
---------------------------------------------------------------------------------*/

$desktop-main-nav-item-padding-left-right: 13px !default;
$desktop-main-nav-font-family: $main-font-family !default;

/*--------------------------------------------------------------------------------
PC NAV ITEMS - LEVEL 1
--------------------------------------------------------------------------------*/
$desktop-main-nav-level-1-height: 40px !default;
$desktop-main-nav-level-1-padding-top: 13px !default;
$desktop-main-nav-level-1-padding-y: 10px !default;
$desktop-main-nav-level-1-text-align: center !default;

$desktop-main-nav-level-1-font-size: 1.3em !default;
$desktop-main-nav-level-1-text-transform: none !default;
// Normal
$desktop-main-nav-level-1-color: #444 !default;
$desktop-main-nav-level-1-background-color: transparent !default;
// Hover
$desktop-main-nav-level-1-hover-color: $primary !default;
$desktop-main-nav-level-1-hover-background-color: transparent !default;
// Active
$desktop-main-nav-level-1-active-color: $desktop-main-nav-level-1-hover-color !default;
$desktop-main-nav-level-1-active-background-color: $desktop-main-nav-level-1-hover-background-color !default;
// Current
$desktop-main-nav-level-1-current-color: $desktop-main-nav-level-1-active-color !default;
$desktop-main-nav-level-1-current-background-color: $desktop-main-nav-level-1-active-background-color !default;
// Highlighted
$desktop-main-nav-level-1-highlighted-color: $desktop-main-nav-level-1-hover-color !default;
$desktop-main-nav-level-1-highlighted-background-color: $desktop-main-nav-level-1-hover-background-color !default;


/*--------------------------------------------------------------------------------
PC NAV ITEMS - LEVEL 2
--------------------------------------------------------------------------------*/
$desktop-main-nav-level-2-padding-y: 13px !default;
$desktop-main-nav-level-2-min-width: 240px !default;
$desktop-main-nav-level-2-font-size: 1.2em !default;

$desktop-main-nav-level-2-font-weight: $main-nav-font-weight !default;
$desktop-main-nav-level-2-padding-x: 15px !default;
$desktop-main-nav-level-2-text-transform: uppercase !default;
$desktop-main-nav-level-2-color: $desktop-main-nav-level-1-color !default;
$desktop-main-nav-level-2-background-color: #fafafa !default;
$desktop-main-nav-level-2-hover-color: $desktop-main-nav-level-1-hover-color !default;
$desktop-main-nav-level-2-hover-background-color: $desktop-main-nav-level-2-background-color !default;
$desktop-main-nav-level-2-active-color: $desktop-main-nav-level-1-hover-color !default;
$desktop-main-nav-level-2-active-background-color: $desktop-main-nav-level-2-background-color !default;

$desktop-main-nav-level-3-background-color:  $desktop-main-nav-level-2-background-color !default;

$mobile-main-nav-toggler-top-bottom-padding: 18px !default;
$mobile-main-nav-toggler-side-padding: 20px !default;
$mobile-main-nav-toggler-background-color: transparent !default;
$mobile-main-nav-toggler-hover-background-color: transparent !default;
$mobile-main-nav-toggler-active-background-color: $mobile-main-nav-toggler-hover-background-color !default;
$mobile-main-nav-toggler-icon-fill: $primary !default;
$mobile-main-nav-toggler-hover-icon-fill: $link-hover-color !default;

$mobile-main-nav-font-weight: $main-nav-font-weight !default;
$mobile-main-nav-font-size: 1.5em !default;
$mobile-main-nav-text-transform: none !default;

$mobile-main-nav-item-padding-y: 15px !default;
$mobile-main-nav-item-border-top-bottom-width: 1px !default;
$mobile-main-nav-item-top-bottom-border-color: #ccc !default;
$mobile-main-nav-item-top-bottom-border-style: solid !default;

$mobile-main-nav-level-1-padding-x: 20px !default;
$mobile-main-nav-level-1-color: #888 !default;
$mobile-main-nav-level-1-background-color: #fff !default;
$mobile-main-nav-level-1-active-color: #000 !default;
$mobile-main-nav-level-1-active-background-color: #eee !default;

$mobile-main-nav-level-2-color: #999 !default;
$mobile-main-nav-level-2-background-color: #fff !default;
$mobile-main-nav-level-2-active-color: #000 !default;
$mobile-main-nav-level-2-active-background-color: #eee !default;


