$sizes: (25, 33, 50, 66, 100);

.responsive-image {
  margin-bottom: $spacer-lg;
  @each $size in $sizes {
    &.max-width-#{$size} {
      $size: #{$size}#{"%"};
      max-width: calc(#{$size} - #{$spacer-md});
      float: left;
      margin-right: $spacer-md;
    }
  }
  @media (min-width: 980px) {
    @each $size in $sizes {
      &.desktop-max-width-#{$size} {
        $size: #{$size}#{"%"};
        max-width: calc(#{$size} - #{$spacer-md});
        float: left;
        margin-right: $spacer-md;
      }
    }
  }
  + *:not(.responsive-image) {
    clear: both;
  }
  &.responsive-image-align-center {
    margin-left: auto;
    margin-right: auto;
    display: block;
    float: none;
  }
  &.responsive-image-align-right {
    float: right;
  }
}

.embedded-video {
  clear: both;
}