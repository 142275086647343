$sm-link-fill: #999 !default;
$sm-link-hover-fill: #666 !default;
$sm-link-max-height-width: 30px !default;

.sm-link {
  display: block;
  margin: 0 $spacer-sm;
  svg {
    width: $sm-link-max-height-width;
    height: $sm-link-max-height-width;
    fill: $sm-link-fill;
  }
  &:hover svg {
    fill: $sm-link-hover-fill;
  }
}

