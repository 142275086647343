@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;1,400&display=swap');
@import './functions';
@import './variable-overrides.scss';
@import './pandago/all-variables';

@import './_text';
@import './_dynamic-header';
@import './_functions';
@import './_general-site-layout';
@import './_main-nav';
@import './_media-elements';
@import './_misc';
@import './_padding-margin';
@import './_blog-layout';
@import './_next-prev-links';
@import './_buttons';
@import './tables';
// @import './_search-box';
@import './_sm-links';
@import './_sidebar';
@import './hugo-sundries/accordion';

@import './hugo-sundries/image-gallery';
@import './hugo-sundries/_loader';
@import './hugo-sundries/addons/_fancybox-4';
@import './addons/_fancybox-4-customisations';
@import './modal';
@import './hugo-sundries/_loader';
@import './hugo-sundries/search-results';

@import './hugo-sundries/addons/_responsive-image';
@import './additional-styles/member-schools';
@import './additional-styles/home';

@media (min-width: 980px) {
    nav.main ul.menu.level-1 > li {
      &:not(:last-child) {
        border-right: 1px solid #999;
      }
    }
    header {
    border-bottom: 5px solid $primary;
    body:not(.scrolled) & {
      border-bottom: 12px solid $primary;
    }
  }
}

.search-switcher {
  height: $desktop-main-nav-level-1-height;
  svg {
    fill: $primary;
  }
  &:hover {
    background: $desktop-main-nav-level-1-hover-background-color;
  }
}

.search-switcher {
  background: transparent;
  border-width: 0;
  svg {
    height: 20px;
    fill: $primary;
  }
}


.ac-trigger h2 {
  color: $secondary;
}

.news {
  .content-list-item {
    background: #fcfcfc;
    border: 1px solid #ccc;
    padding: $spacer-lg;
    margin-bottom: $spacer-lg;
    h2 {
      font-size: 1.6em;
      color: $secondary;
      // width: calc(100% - 212px);
      // float: right;
    }
  }
}

.news-item {
  h1 {
    text-align: left;
  }
}

blockquote {
  font-size: 120%;
  padding-left: $spacer-lg;
  padding-right: $spacer-lg;
  padding-top: 20px
}

blockquote:before {
font-family: Georgia, serif;
top: -10px;
line-height: 100%;

}

#next-prev {
  background: #eee;

}

#home-intro .body-text {
  font-size: 1.8em;
  max-width: 450px;
  text-align: center;
  p {
    text-align: center;
  }
}

.section-menu {
  li {
    display: flex;
    align-items: flex-start;
    img {
      width: 60px;
      height: 60px;
      object-fit: cover;
      margin: $spacer-sm $spacer-md 0 0;
      flex-shrink: 0;
    }
    a {
      font-size: #{1.3em/$body-text-font-size}em;
      line-height: 110%;
    }
    .publish-date {
      font-size: #{1.1em/$body-text-font-size}em;
    }
  }
}

.section-menu {
  max-height: 430px;
  overflow-y: auto;
}

section {
  background-color: #fff;
  background-image: url("/theme/white-bg.png");
  background-repeat: repeat;
  background-position: center top
}

.downloads-container {
  max-width: 1440px;
  .downloads-section {
    border: 1px solid #ccc;
    background: #fcfcfc;
    max-width: 360px;
    width: 100%;
    margin: $spacer-md;
    @media(min-width: 780px) {
      width: calc(50% - #{$spacer-lg});
    }
    @media(min-width: 1440px) {
      margin: $spacer-md;
      width: calc(25% - #{$spacer-lg});
    }
    h3 {
      margin: 0;
      padding: $spacer-md;
      text-align: center;
      border-bottom: 1px solid #ccc;
      color: $primary;
    }
    ul {
      height: 400px;
      overflow-y: scroll;
    }
    li {
      padding: $spacer-md;
      &:first-child {
        border-width: 0;
      }
      a {
        color: #777;
        &:hover {
          color: #555;
          text-decoration: underline;
        }
      }
    }
  }
}

// .search-result-href {
//   a {
//     display: none;
//   }
// }

// .search-result {
//   display: none;
// }

// .search-result {
//   &:nth-child(11) {
//     display: block;
//   }
// }



// .search-result-snippet {
//   overflow-wrap: break-word;
// }