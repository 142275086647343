.fancybox__slide {
  padding-top: 30px;
  padding-bottom: 30px;
}

.fancybox__image {
  margin: 0;
}

.fancybox__backdrop {
  opacity: 1 !important;
  background: #111;
}

.fancybox__caption  {
  font-size: 1.3em;
}