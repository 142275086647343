*,
*::before,
*::after {
  box-sizing: border-box; // 1
}

html {
  padding: 0;
  margin: 0;
  height: 100%;
}

body {
  background: $body-background-color;
  font-family: $main-font-family;
  margin: 0;
  padding: 0;
  font-size: 62.5%;
  min-width: 320px;
  height: 100%;
  overflow: hidden;
}

@media (max-width: 480px) {
  body {
    font-size: 56%;
  }
}

#wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.panels {
  flex-grow: 1;
}

.panel {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  padding-top: $spacer-xl;
  padding-bottom: $spacer-xl;

  &.dark-bg {
    background-color: $panel-dark-bg-default-background-color;
  }
}

section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content-max-width {
  max-width: $pc-content-container-max-width;
  width: 100%;
}

.page-gutters {
  padding-left: $pc-page-gutter-left-right;
  padding-right: $pc-page-gutter-left-right;
}

header {
  width: 100%;
  box-shadow: $desktop-header-box-shadow;
  background-color: $desktop-header-background-color;
  display: flex;
  justify-content: center;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: $desktop-header-container-max-width;
    width: 100%;
  }
}

header .container {
  justify-content: flex-start;
  .logo-container {
    flex-grow: 1;
  }

  .mobile-nav-toggler {
    order: 40;
  }

  @media (min-width: #{$mobile-menu-threshold + 1}) {
    & > *:last-child {
      margin-right: $spacer-xl;
    }
  
    nav {
      margin: 0;
      &:last-child {
        margin-right: $spacer-xl - $desktop-main-nav-item-padding-left-right;
      }
    }
  }
}

@media (max-width: 979px) {
  .desktop-nav {
    display: none;
  }
}

@media (min-width: 980px) {
  header {
    .mobile-nav {
      display: none;
    }

    .desktop-nav {
      display: block;
      flex-grow: 1;
    }
  }
}


.logo-container {
  padding-top: $mobile-logo-spacing-y;
  padding-bottom: $mobile-logo-spacing-y;
  margin-left: $mobile-logo-margin-left;
  margin-right: $mobile-logo-margin-right;

  img {
    display: block;
  }
}


@media (min-width: 980px) {
  .logo-container {
    margin-left: $desktop-logo-margin-left;
    margin-right: $desktop-logo-margin-right;
    padding-top: $desktop-logo-spacing-y;
    padding-bottom: $desktop-logo-spacing-y;

    img {
      width: $desktop-logo-width;
      height: $desktop-logo-height - 2*$desktop-logo-spacing-y;
    }
  }
}

.desktop-nav {
  flex-grow: 1;
}

.banner {
  margin-bottom: $banner-margin-bottom;

  img {
    display: block;
    margin: 0;
  }
}

@media (max-width: 979px) {
  .banner {
    display: $mobile-banner-display;
  }
}

footer {
  background: $footer-background-color;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: $footer-info-color;
  font-style: $footer-info-font-style;
  font-weight: $footer-info-font-weight;
  font-family: $footer-info-font-family;
  text-transform: $footer-info-text-transform;
  font-size: $footer-info-font-size;
  line-height: 150%;
  padding: $spacer-lg;

  .footer-item {
    margin: 0 $spacer-md;
    display: flex;
    align-items: center;
  }

  svg {
    height: 20px;
  }

  .sm-links {
    width: 100%;
    margin-bottom: $spacer-md;
    justify-content: center;
  }

  @media (max-width: 979px) {
    flex-direction: column;
  }
}

footer a {
  display: flex;
  align-items: center;
  color: $footer-info-link-color;
  text-decoration: $footer-info-link-text-decoration;
}

footer a:hover {
  color: $footer-info-link-hover-color;
  text-decoration: $footer-info-link-hover-text-decoration;
}

div.buttons-png-loader {
  height: 0;
  width: 0;
  opacity: 0;
  background-image: url("images/buttons.png");
}

body.custom-error-page div#main-content .container-2 {
  max-width: 700px;
}

iframe {
  max-width: 100%;
}

.list-page-intro {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: $spacer-xl;

  .list-page-intro-content {
    max-width: 700px;
  }
}

div.section-content {
  display: flex;
  justify-content: center;
}

.main-block {
  flex-grow: 1;
  max-width: $main-block-max-width;
  width: 100%;
}

@media (min-width: 980px) {
  .show-below-979px {
    display: none;
  }

  .hide-below-979px {
    display: block;
  }
}

@media (max-width: 979px) {
  .show-below-979px {
    display: block;
  }

  .hide-below-979px {
    display: none;
  }

  .page-gutters {
    padding-left: 3%;
    padding-right: 3%;
  }

  .logo-container {
    margin-right: $spacer-lg;
  }
}

.d-flex {
  display: flex;

  &.justify-center {
    justify-content: center;
  }

  &.justify-space-between {
    justify-content: space-between;
  }

  &.justify-flex-start {
    justify-content: flex-start;
  }

  &.justify-flex-end {
    justify-content: flex-end;
  }

  &.align-center {
    align-items: center;
  }

  &.direction-column {
    flex-direction: column;
  }

  &.direction-row {
    flex-direction: row;
  }

  &.flex-wrap {
    flex-wrap: wrap;
  }
}

.flex-align-flex-start {
  align-items: flex-start;
  flex-grow: 0;
}


.flex-grow-1 {
  flex-grow: 1;
}


#p404 {
  .text .byline {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }
}

.width-100 {
  width: 100%;
}

.max-width-100 {
  max-width: 100%;
}

.width-50 {
  width: 50%;
}

.max-width-700px {
  max-width: 700px;
}

.max-width-900px {
  max-width: 900px;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
  top: 0;
}

.position-fixed {
  position: fixed;
  bottom: 0;
}

.bottom-0 {
  bottom: 0
}

// IE Hacks
@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .panels {
    flex-shrink: 0;

    .panel.min-height-100vh {
      height: 0px;
    }
  }

  .container {
    width: 100%;
  }

  header .logo-container svg {
    width: initial;
  }
}

.unsuported-browser-page-logo {
  svg {
    width: 400px;
    height: 150px;
  }
}

.ie-text-wrap {
  width: 100%;
}

.object-fit-cover,
[data-object-fit="cover"] {
  object-fit: cover;
}

#panorama img {
  margin: 0;
  display: block;
}

.google-map {
  width: 100%;
  height: 400px;
}

.min-height-100vh {
  min-height: 100vh;
}

.height-100vh {
  height: 100vh
}

.grid-layout {
  display: flex;
  flex-wrap: wrap;
  margin: #{-1*$grid-layout-item-margin};
  .content-list-item {
    width: column-width(2);
    padding: $grid-layout-item-padding;
    margin: $grid-layout-item-margin;
    background: white;
  }
}

#panorama {
  picture, img {
    width: 100%;
  }
}

.list-page-intro {
  max-width: $list-page-intro-max-width;
}

.clear {
  clear: both;
}