.image-gallery {
  flex-wrap: wrap;
  display: flex;
  width: 100%;
  max-width: 980px;
  a.gallery-thumbnail {
    width: calc(33.3% - 2*#{$spacer-sm});
    display: block;
    background-size: cover;
    background-position: center center;
    background-color: #ccc;
    overflow: hidden;
    margin: $spacer-sm;
    img {
      width: 101%;
      height: 101%;
      object-fit: cover;
      margin-bottom: 0;
      display: block;
    }
  }
  @media (max-width: 979px) {
    a.gallery-thumbnail {
      width: calc(33.3% - #{$spacer-sm});
      margin: $spacer-sm/2;
    }
  }
}

.load-more-images {
  width: 100%;
  display: block !important;
}


 