// Full page of blog and blog list items on blog list page



.blog-layout img.item-thumbnail   {
	width: 100%;
	max-width: $blog-item-image-block-max-width;
	margin: $blog-item-image-margin-top $blog-item-image-margin-right $blog-item-image-margin-bottom 0;
	float: left;
	display: block;
	border: $blog-item-image-block-border-width solid $blog-item-image-block-border-color;
}


// Blog list items on blog list page only

.blog-layout .content-list-item {
  clear: both;
  &:not(:first-child) {
    @if $blog-item-border-bottom {
      border-top: $blog-item-border-bottom-width $blog-item-border-bottom-style $blog-item-border-bottom-color;
      
    }
  }
	&:not(:first-child) {
    padding-top: $blog-item-padding-top;
  }
  &:not(:last-child) {
    padding-bottom: $blog-item-padding-bottom;
  }
}

.blog-layout .content-list-item .item-title {
	margin-top: $blog-item-header-margin-top;
}

.blog-layout .content-list-item .item-title a:link {
	color: inherit;
  text-decoration: none;
  font-weight: inherit;
}

.blog-layout .content-list-item .item-title a:visited {
	color: $link-visited-color;
	text-decoration: none;
}

.blog-layout .content-list-item .item-title a:hover {
	color: $link-hover-color;
	text-decoration: $link-hover-text-decoration;
}

.blog-layout .content-list-item div.item-summary {
	text-align: justify;
	margin-bottom: 20px;
}

.blog-layout .content-list-item a.more-link {
	display: inline-block;
	padding: $item-read-more-padding-top-bottom $item-read-more-padding-left-right;
	background: $item-read-more-background-color;
	-webkit-border-radius: $item-read-more-border-radius;
	-moz-border-radius: $item-read-more-border-radius;
	border-radius: $item-read-more-border-radius;
	color: $item-read-more-color;
	font-weight: $item-read-more-font-weight;
	text-decoration: $item-read-more-link-text-decoration;
	font-family: $item-read-more-font-family;
	font-size: $item-read-more-font-size;
	text-transform: $item-read-more-text-transform;
}

.blog-layout .content-list-item a.more-link:hover {
	color: $item-read-more-hover-color;
	text-decoration: $item-read-more-link-hover-text-decoration;
}

.blog-layout .content-list-item a.post-thumbnail {
	display: block;
	float: left;
	max-width: $blog-item-image-block-max-width;
}

.taxonomy-list {
  font-size: $taxonomy-list-font-size;
  margin-bottom: $spacer-lg;
  ul {
    display: flex;
    
    li {
      list-style: none;
      margin-right: $spacer-lg;
      &.list-header {
        margin-left: 0;
        padding-left: 0;
        margin-right: $spacer-md;
        color: $taxonomy-list-header-color;
      }
      a {
        text-decoration: $taxonomy-list-link-text-decoration;
        &:hover {
          text-decoration: $taxonomy-list-link-hover-text-decoration;
        }
      }
    }
  }
}