$svg-data-uri-icon-tick: url('data:image/svg+xml !default;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 28"  class="single-colour tick"><path fill="%23fff" d="M13 28c-0.7 0-1.3-0.2-1.9-0.7l-10-8c-1.3-1-1.5-2.9-0.5-4.2 1-1.3 2.9-1.5 4.2-0.5l7.9 6.3L32.9 0.9c1.2-1.2 3.1-1.2 4.2 0 1.2 1.2 1.2 3.1 0 4.2l-22 22C14.5 27.7 13.8 28 13 28z"/></svg>') !default;

$svg-data-uri-arrow-right: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18.9 36.8'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cpolyline class='st0' points='0.5 0.5 18.4 18.4 0.5 36.3 '/%3E%3C/svg%3E") !default;

$svg-data-uri-arrow-left: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18.9 36.8'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cpolyline class='st0' points='18.4 0.5 0.5 18.4 18.4 36.3 '/%3E%3C/svg%3E") !default;

$svg-data-uri-arrow-up: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36.8 18.9'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cpolyline class='st0' points='36.3 18.4 18.4 0.5 0.5 18.4 '/%3E%3C/svg%3E") !default;

$svg-data-uri-arrow-down: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36.8 18.9'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cpolyline class='st0' points='36.3 0.5 18.4 18.4 0.5 0.5 '/%3E%3C/svg%3E") !default;

$external-link-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' fill='%23aaa'%3E%3Cpath d='M448 80v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48zm-88 16H248c-21.3 0-32.1 25.9-17 41l32 32L67.5 364.5c-4.7 4.7-4.7 12.3 0 17l31 31c4.7 4.7 12.3 4.7 17 0l195.5-195.5 32 32C358.1 264 384 253.4 384 232V120c0-13.3-10.7-24-24-24z'/%3E%3C/svg%3E") !default;

$search-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 35.3 37.8' fill='%23aaa' %3E%3Cpath class='st0' d='M22.2 0C15 0 9.1 5.9 9.1 13.1c0 3.1 1.1 5.9 2.8 8.1L0.5 34.4c-0.7 0.8-0.6 2.1 0.2 2.8 0.4 0.3 0.8 0.5 1.3 0.5 0.6 0 1.1-0.2 1.5-0.7L14.9 24c2.1 1.4 4.6 2.2 7.3 2.2 7.2 0 13.1-5.9 13.1-13.1C35.3 5.9 29.4 0 22.2 0zM22.2 22.2c-5 0-9.1-4.1-9.1-9.1 0-5 4.1-9.1 9.1-9.1 5 0 9.1 4.1 9.1 9.1C31.3 18.1 27.2 22.2 22.2 22.2z'/%3E%3C/svg%3E") !default;