$table-border-color: #ccc !default;
$table-header-cell-color: inherit !default;
$table-header-cell-background-color: #fff !default;
$table-header-cell-border-color: #ccc !default;
$table-header-cell-text-transform: none !default;
$table-header-cell-font-family: inherit !default;
$table-header-cell-font-weight: 700 !default;
$table-header-cell-font-size: inherit !default;

$table-cell-padding-top: 5px !default;
$table-cell-padding-bottom: 5px !default;
$table-cell-padding-left-right: 8px !default;
$table-cell-text-line-height: 120% !default;
$table-cell-background-color: #fff !default;
$table-cell-color: $body-text-color !default;
$table-cell-font-family: $main-font-family !default;