nav.main {
	font-family: $desktop-main-nav-font-family;
	font-size: 1em;
}

nav.main ul {
   list-style: none;
}

nav.main ul li {
	padding: 0;
	margin: 0;
	font-size: 1em;
	line-height: 100%;
}

nav.main ul li a,
nav.main ul li span {
	box-sizing: border-box;
	text-decoration: none;
	display: block;
}

nav.main ul li a:hover {
	cursor: pointer;
}

nav.main ul.menu.level-1 > li {
	position: relative;
}

nav.main ul li.active > a:hover {
	cursor: default;
}

@media (min-width: $mobile-menu-threshold + 1) {
	.mobile-nav-toggler {
		display: none;
	}

	nav.main {
		display: flex;
		justify-content: flex-end;
    .nav-ul-container {
      height: auto !important;
    }
    
	}

  nav.main + .btn {
    margin-left: $desktop-main-nav-item-padding-left-right;
  }

	nav.main ul.menu {
		display: flex;
	}

	nav.main ul li a,
	nav.main ul li span {
		padding-left: $desktop-main-nav-item-padding-left-right;
		padding-right: $desktop-main-nav-item-padding-left-right;
  }
  
  nav.main ul li span:hover {
    cursor: default;
  }


	nav.main ul.menu > li {
		position: relative;
	}
	nav.main ul.menu.level-1 > li > a,
	nav.main ul.menu.level-1 > li > span {
		height: $desktop-main-nav-level-1-height;
    display: flex;
		align-items: center;
		font-weight: $main-nav-font-weight;
		font-size: $desktop-main-nav-level-1-font-size;
		color: $desktop-main-nav-level-1-color;
		text-transform: $desktop-main-nav-level-1-text-transform;
		background-color: $desktop-main-nav-level-1-background-color;
		padding-top: $desktop-main-nav-level-1-padding-y;
		padding-bottom: $desktop-main-nav-level-1-padding-y;
		text-align: left;
		white-space: nowrap;
	}

	nav.main ul.menu.level-1 > li > a:focus,
	nav.main ul.menu.level-1 > li > a:hover,
  nav.main ul.menu.level-1 > li > span:hover,
  nav.main ul.menu.level-1 > li.parent > a:focus,
	nav.main ul.menu.level-1 > li.parent > a:hover,
	nav.main ul.menu.level-1 > li.parent > span:hover {
		color: $desktop-main-nav-level-1-hover-color;
		background-color: $desktop-main-nav-level-1-hover-background-color;
	}
	nav.main ul.menu.level-1 > li.active,
	nav.main ul.menu.level-1 > li.active > a,
	nav.main ul.menu.level-1 > li.active > span  {
		color: $desktop-main-nav-level-1-active-color;
		background-color: $desktop-main-nav-level-1-active-background-color;
  }
  
  nav.main ul.menu.level-1 > li > a:focus,
	nav.main ul.menu.level-1 > li > a:hover,
	nav.main ul.menu.level-1 > li > span:hover {
		color: $desktop-main-nav-level-1-hover-color;
		background-color: $desktop-main-nav-level-1-hover-background-color;
	}

	nav.main ul.menu.level-1 > li.highlighted > a,
	nav.main ul.menu.level-1 > li.highlighted > span {
		color: $desktop-main-nav-level-1-highlighted-color;
		background-color: $desktop-main-nav-level-1-highlighted-background-color;
	}

	nav.main ul.level-2 {
		position: absolute;
		top: $desktop-main-nav-level-1-height;
		left: 0;
		z-index: 1000;
		display: none;
		min-width: 150px;
	}

	nav.main ul.menu > li:hover > .level-2 {
		display: block;
	}

	nav.main ul.level-2 li a,
	nav.main ul.level-2 li span  {
		color: $desktop-main-nav-level-2-color;
		background-color: $desktop-main-nav-level-2-background-color;
		font-weight: $desktop-main-nav-level-2-font-weight;
		font-size: $desktop-main-nav-level-2-font-size;
		text-transform: $desktop-main-nav-level-2-text-transform;
		padding-top: $desktop-main-nav-level-2-padding-y;
		padding-bottom: $desktop-main-nav-level-2-padding-y;
		min-width: $desktop-main-nav-level-2-min-width;
    white-space: nowrap;
	}

	nav.main ul.level-2 li > a:hover,
	nav.main ul.level-2 li > a:focus,
	nav.main ul.level-2 li > span:hover {
		color: $desktop-main-nav-level-2-hover-color;
		background-color: $desktop-main-nav-level-2-hover-background-color;
	}

	nav.main ul.level-2 li.active > span,
	nav.main ul.level-2 li.active > a,
	nav.main ul.level-2 li.current-menu-ancestor > a {
		color: $desktop-main-nav-level-2-active-color;
		background-color: $desktop-main-nav-level-2-active-background-color;
  }
  
  nav.main ul.level-2 li {
    .divider {
      border-top: 1px solid #ccc;
    }
  }

  nav.main ul.level-3 {
    flex-direction: column;
    li {
      a, span {
        padding-left: 3*$desktop-main-nav-item-padding-left-right;
		    background-color: $desktop-main-nav-level-3-background-color;
      }
      
    }
  }
}

@media (max-width: $mobile-menu-threshold) {
	.mobile-nav-toggler {
		background-color: $mobile-main-nav-toggler-background-color;
		width: 22px + 2*$mobile-main-nav-toggler-side-padding;
		height: 22px;
		padding: 0 $mobile-main-nav-toggler-side-padding;
		box-sizing: inherit;
		clear: both;
		border-radius: 0;
		border-width: 0;
	}

	.mobile-nav-toggler:hover {
		cursor: pointer;
		background-color: $mobile-main-nav-toggler-hover-background-color;
	}

	.mobile-nav-toggler.active {
		background-color: $mobile-main-nav-toggler-active-background-color;
	}

	.mobile-nav-toggler span.icon-bar {
		display: block;
	  -webkit-border-radius: 2px;
	  -moz-border-radius: 2px;
	  border-radius: 2px;
		height: 3px;
		background-color: $mobile-main-nav-toggler-icon-fill;
		width: 22px;
	}
	.mobile-nav-toggler span.icon-bar + span.icon-bar {
		margin-top: 4px;
	}
  nav.main {
    width: 100%;
  }
  
	nav.main .nav-ul-container {
		// width: 100%;
		height: 0;
		overflow: hidden;
		position: relative;
    -webkit-transition: height .35s ease;
    -moz-transition: height .35s ease;
    -o-transition: height .35s ease;
    transition: height .35s ease;
    transition-property: height;
    transition-duration: .35s;
    transition-timing-function: ease;
    transition-delay: initial;
	}

	nav.main ul li > a,
	nav.main ul > li > span {
		padding-top: $mobile-main-nav-item-padding-y;
		padding-bottom: $mobile-main-nav-item-padding-y;
		font-weight: $mobile-main-nav-font-weight;
		font-size: $mobile-main-nav-font-size;
		text-transform: $mobile-main-nav-text-transform;
	}

	nav.main > ul.menu {
		margin: 0;
	}
	nav.main ul.menu li {
		border-top: 1px solid $mobile-main-nav-item-top-bottom-border-color;
	}

	nav.main ul.menu > li:last-child {
		border-bottom: $mobile-main-nav-item-border-top-bottom-width $mobile-main-nav-item-top-bottom-border-style $mobile-main-nav-item-top-bottom-border-color;
	}

	nav.main ul.menu > li > a,
	nav.main ul.menu > li > span {
		color:  $mobile-main-nav-level-1-color;
		background-color: $mobile-main-nav-level-1-background-color;
		padding-left: $mobile-main-nav-level-1-padding-x;
		padding-right: $mobile-main-nav-level-1-padding-x;
	}

	nav.main ul.menu > li.active > a,
	nav.main ul.menu > li.active > span {
		color:  $mobile-main-nav-level-1-active-color;
		background-color:  $mobile-main-nav-level-1-active-background-color;
	}

	nav.main ul.level-2 li a,
	nav.main ul.level-2 li span {
		color: $mobile-main-nav-level-2-color;
		background-color: $mobile-main-nav-level-2-background-color;
		padding-left: 2*$mobile-main-nav-level-1-padding-x;
		padding-right: 2*$mobile-main-nav-level-1-padding-x;
	}

	nav.main ul.level-2 li.active > a,
	nav.main ul.level-2 li.active > span {
		color: $mobile-main-nav-level-2-active-color;
		background-color: $mobile-main-nav-level-2-active-background-color;
	}
}

