.next-prev-links {
  @media (max-width: 670px) {
    flex-wrap: wrap;
    flex-direction: column;
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:first-child {
      align-self: flex-start;
      padding-right: $spacer-lg;
      @media (max-width: 670px) {
        padding-bottom: $spacer-xl;
      }
    }
    &:last-child {
      align-self: flex-end;
      padding-left: $spacer-lg;
      text-align: right;
    }
  }
  .next-prev-arrows {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: #fff;
    font-weight: #666;
    flex-shrink: 0;
    text-decoration: none;
    color: #222;
    &:first-child {
      margin-right: $spacer-md;
    }
    &:last-child {
      margin-left: $spacer-md;
    }
  }
}
