

.search-results-summary {
  font-size: 1.5em;
}

.search-result {
  margin: $spacer-xl 0;
  padding-top: $spacer-xl;


  border-top: 1px solid #777;
}

.highlighted {
  color: $primary;
}