$primary: red !default;
$spacer-sm: 4px !default;
$spacer-md: 8px !default;
$spacer-lg: 16px !default;
$spacer-xl: 32px !default;
@import './functions';
@import './variables/_variables-text';
@import './variables/_variables-tables';
@import './variables/_variables-misc';
@import './variables/_variables-form-controls';
@import './variables/_variables-data-svgs';
@import './variables/_variables-buttons';
@import './variables/_variables-blog-layout';
@import './variables/_variables-general-site-layout';
@import './variables/_variables-main-nav';
@import './variables/_variables-pagination-links';
@import './variables/variables-dynamic-header';
@import './variables/variables-media-elements';
@import './mixins/_mixins';
