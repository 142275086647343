button,
[role="button"] {
  cursor: pointer;
}
.btn {
  background: $button-background-color;
  border: $button-border-width $button-border-style $button-border-color;
  border-radius: $button-border-radius;
  color: $button-color;
  font-weight: $button-font-weight;
  font-family: $button-font-family;
  text-transform: $button-text-transform;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: $button-padding;
  text-decoration: none;
  font-size: $button-font-size;
  height: auto;
  svg {
    margin-right: 10px;
    vertical-align: middle;
    height: 16px;
    width: auto;
    fill: $button-color;
  }
  .body-text & {
    font-size: #{$button-font-size/$body-text-font-size}em;
  }
  &:hover {
    color: $button-hover-color;
    border-color: $button-hover-border-color;
    background: $button-hover-background-color;
    cursor: pointer;
    text-decoration: none;
  }
  .dark-bg & {
    color: $dark-bg-button-color;
    border-color: $dark-bg-button-border-color;
    background-color: $dark-bg-button-background-color;
    svg {
      fill: $dark-bg-button-color;
    }
  }
  &.btn-outline {
    border-color: $button-outline-border-color;
    color: $button-outline-color;
    background: transparent;
    svg {
      fill: $button-outline-color;
    }
    &:hover {
      color: #fff;
      background: $button-outline-color;
      svg {
        fill: #fff;
      }
    }
    
  }
  &.btn-outline-white {
    border-color: #fff;
    color: #fff;
    background: transparent;
    &:hover {
      color: #222;
      background: #fff;
      svg {
        fill: #222;
      }
    }
  }

  &.btn-lg {
    padding: $button-lg-padding;
  }

  
}

button.no-styles {
  padding: 0;
  background: transparent;
  border-width: 0;
}