$primary: #5db442;
$secondary: #3e742d;

$main-font-family:  "Open Sans", sans-serif;

$blog-item-border-bottom-width: null;
// $blog-item-border-bottom-color:  #777;
// $blog-item-border-bottom-style:  solid;
$blog-item-padding-top:  15px;
$blog-item-padding-bottom:  15px;
$blog-item-image-block-max-width:  200px;
$blog-item-image-margin-bottom:  7px;
$blog-item-image-margin-right: 12px;
$blog-item-header-margin-top:  -2px;


$desktop-header-background-color:  #222222;
$footer-background-color:  #222222;

$dynamic-header-height: 70px;
$dynamic-header-desktop-initial-height: 144px;
$desktop-initial-logo-spacing-y: 27px;

$sidebar-box-width:  100%;
$sidebar-box-border-color:  #ccc;
$sidebar-box-border-style:  solid;
$sidebar-box-border-width:  1px;

$footer-info-color:  #fff;
$footer-info-font-style:  normal;
$footer-info-font-size:  1.2em;
$footer-info-font-weight:  normal;
$footer-info-font-family:  $main-font-family;
$footer-info-text-transform:  none;
$footer-info-link-color:  #fff;
$footer-info-link-text-decoration:  underline;
$footer-info-link-hover-color:  green;
$footer-info-link-hover-text-decoration:  underline;

$main-nav-font-weight:  600;
$desktop-main-nav-item-padding-left-right:  10px;
$desktop-main-nav-font-family:  $main-font-family;
$desktop-main-nav-level-1-height:  20px;
$desktop-main-nav-level-1-padding-top:  5px;
$desktop-main-nav-level-1-text-align:  center;
$desktop-main-nav-level-1-font-size:  1.4em;
$desktop-main-nav-level-1-text-transform:  none;
$desktop-main-nav-level-1-color:  #fff;
$desktop-main-nav-level-1-background-color:  transparent;
$desktop-main-nav-level-1-hover-color:  #5DB442;
$desktop-main-nav-level-1-hover-background-color:  transparent;
$desktop-main-nav-level-1-active-color:  #5DB442;
$desktop-main-nav-level-1-active-background-color:  transparent;
$desktop-main-nav-level-1-highlighted-color:  #5DB442;
$desktop-main-nav-level-1-highlighted-background-color:  transparent;
$desktop-main-nav-level-2-font-size:  $desktop-main-nav-level-1-font-size;
$desktop-main-nav-level-2-font-weight:  $main-nav-font-weight;
$desktop-main-nav-level-2-text-transform:  none;
$desktop-main-nav-level-2-color:  #fff;
$desktop-main-nav-level-2-background-color:  #222;
$desktop-main-nav-level-2-hover-color:  #5DB442;
$desktop-main-nav-level-2-hover-background-color:  $desktop-main-nav-level-2-background-color;
$desktop-main-nav-level-2-active-color:  $desktop-main-nav-level-2-color;
$desktop-main-nav-level-2-active-background-color:  $desktop-main-nav-level-2-background-color;

$mobile-main-nav-font-weight:  $main-nav-font-weight;
$mobile-main-nav-font-size:  1.6em;
$mobile-main-nav-text-transform:  none;
$mobile-main-nav-item-border-top-bottom-width:  1px;
$mobile-main-nav-item-top-bottom-border-style:  solid;
$mobile-main-nav-level-1-color:  #fff;
$mobile-main-nav-level-1-background-color:  #5DB442;
$mobile-main-nav-level-1-active-color:  #fff;
$mobile-main-nav-level-1-active-background-color:  $mobile-main-nav-level-1-background-color;
$mobile-main-nav-level-2-color:  #fff;
$mobile-main-nav-level-2-background-color:  #6fa060;
$mobile-main-nav-level-2-active-color:  #fff;
$mobile-main-nav-level-2-active-background-color:  $mobile-main-nav-level-2-background-color;

$main-header-color:  #222;
$main-header-font-size:  3em;
$main-header-text-align:  center;
$main-header-text-transform:  none;
$main-header-font-family:  $main-font-family;
$main-header-font-weight:  400;

$body-text-font-size:  1.4em;
$body-text-line-height:  150%;
$body-text-color:  #444;
$body-text-font-weight:  400;

$body-text-header-color: #222;
$body-text-header-h2-font-size: 2.4em;
$body-text-header-h3-font-size: 1.8em;
$body-text-header-h4-font-size: 1.6em;

$blockquote-background-color:  transparent;
$blockquote-font-style:  italic;
$blockquote-font-weight:  600;
$blockquote-color:  inherit;

$ac-item-border-radius: 0;
$blockquote-font-weight: 400;


$button-background-color: #fff;
$button-hover-background-color: #eee;
$button-color: #999 ;
$button-border-color: #ccc;
$button-hover-color: #666;
$button-hover-border-color: $button-color;

$button-border-width: 3px;
$button-border-radius: 0;

$button-font-size: 1.8em !default;
$button-text-transform: uppercase;


$button-lg-padding: 20px 30px;