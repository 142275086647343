$desktop-header-height-calculated: $dynamic-header-desktop-height or $dynamic-header-height;
$desktop-header-initial-height-calculated: $dynamic-header-desktop-initial-height or $dynamic-header-initial-height;
$mobile-header-height-calculated: $dynamic-header-mobile-height or $dynamic-header-height;
$mobile-header-initial-height-calculated: $dynamic-header-mobile-initial-height or $dynamic-header-initial-height;

#wrapper {
  transition: $dynamic-header-transition;
}

header {
  transition: $dynamic-header-transition;
  .logo-container {
    transition: $dynamic-header-transition;
    flex-shrink: 0;
    height: 100%;
    a.logo {
      display: inline-block;
    }
    svg, img {
      // height: 100%;
      width: auto;
      transition: $dynamic-header-transition;
      margin: 0;
    }
  }
}

.header-bg-transparent,
.header-bg-start-transparent:not(.scrolled) {
  header {
    background-color: transparent;
    box-shadow: none;
  }
}

.header-text-light,
.header-text-start-light:not(.scrolled) {
  header {
    color: $dynamic-header-light-text-color;
    a {
      color: $dynamic-header-light-text-color;
    }

    .logo-container svg .st0 {
      fill: $dynamic-header-light-text-color;
    }

    nav.main ul.menu>li>a,
    nav.main ul.menu>li>span {
      color: $dynamic-header-light-text-color;
    }

    @media (max-width: 979px) {
      nav.main ul.menu>li {
        border-width: 0;

        a,
        span {
          background-color: rgba(0, 0, 0, 0.4);
        }
      }
    }
  }
}

.header-text-dark,
.header-text-start-dark:not(.scrolled) {
  header {
    color: $dynamic-header-dark-text-color;
    a {
      color: $dynamic-header-dark-text-color;
    }
    .logo-container svg .st0 {
      fill: $dynamic-header-dark-text-color;
    }
    nav.main ul.menu>li>a,
    nav.main ul.menu>li>span {
      color: $dynamic-header-dark-text-color;
    }
  }
}

.header-fixed {
  header {
    position: fixed;
    top: 0;
    z-index: 999;
    @media (max-width: 979px) {
      #wrapper {
        padding-top: $mobile-header-height-calculated;
      }
      nav {
        position: fixed;
        overflow-y: auto;
        top: $mobile-header-height-calculated;
        max-height: calc(100vh - #{$mobile-header-height-calculated});
        transition: $dynamic-header-transition
      }
    }
  }
  &:not(.scrolled) {
    @media (max-width: 979px) {
      &:not(.header-overlay) #wrapper {
        padding-top: $mobile-header-initial-height-calculated;
      }
      nav {
        top: $mobile-header-initial-height-calculated;
        max-height: calc(100vh - #{$mobile-header-initial-height-calculated});
      }
    }
  }
  @media (min-width: 980px) {
    #wrapper {
      padding-top: $desktop-header-height-calculated;
    }

    &:not(.scrolled):not(.header-overlay) #wrapper {
      padding-top: $desktop-header-initial-height-calculated;
    }
  }

  &.header-overlay {
    #wrapper {
      padding-top: 0;
    }
  }
}


@media(min-width: 980px) {
  header {
    height: $desktop-header-height-calculated;
    .logo-container {
      padding: $desktop-logo-spacing-y 0;
      svg, img {
        height: $desktop-header-height-calculated - 2*$desktop-logo-spacing-y; 
      }
    }
  }

  body:not(.scrolled) {
    header {
      height: $desktop-header-initial-height-calculated;
      .logo-container {
        padding: $desktop-initial-logo-spacing-y 0;
        svg, img {
          height: $desktop-header-initial-height-calculated - 2*$desktop-initial-logo-spacing-y;  
        }
      }
    }
  }

  .desktop-header-fixed {
    header {
      position: fixed;
      top: 0;
      z-index: 9999999;
    }
  
    #wrapper {
      padding-top: $desktop-header-height-calculated;
    }
  
    &:not(.scrolled):not(.header-overlay) #wrapper {
      padding-top: $desktop-header-initial-height-calculated;
    }
  
    &.header-overlay {
      #wrapper {
        padding-top: 0;
      }
    }
  }
}

@media(max-width: 979px) {
  header {
    height: $mobile-header-height-calculated;
    .logo-container {
      padding: $mobile-logo-spacing-y 0;
      svg, img {
        height: $mobile-header-height-calculated - 2*$mobile-logo-spacing-y; 
      }
    }
  }

  body:not(.scrolled) {
    header {
    height: $mobile-header-initial-height-calculated;

      .logo-container {
        svg, img {
          height: $mobile-header-initial-height-calculated - 2*$mobile-logo-spacing-y;  
        }
      }
    }
  }

  .mobile-header-fixed {
    header {
      position: fixed;
      top: 0;
      z-index: 9999999;
      @media (max-width: 979px) {
        nav {
          position: fixed;
          overflow-y: auto;
          top: $mobile-header-height-calculated;
          max-height: calc(100vh - #{$mobile-header-height-calculated});
          transition: $dynamic-header-transition
        }
      }
    }
    &:not(.scrolled) {
      @media (max-width: 979px) {
        nav {
          top: $mobile-header-initial-height-calculated;
          max-height: calc(100vh - #{$mobile-header-initial-height-calculated});
        }
      }
    }
  
    #wrapper {
      padding-top: $mobile-header-height-calculated;
    }
  
    &:not(.scrolled):not(.header-overlay) #wrapper {
      padding-top: $mobile-header-initial-height-calculated;
    }
  
    &.header-overlay {
      #wrapper {
        padding-top: 0;
      }
    }
  }
}