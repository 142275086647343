$home-block-row-max-width: 980px;
$single-column-threshold: 1080px;

body.home {
  h2 {
    color: $primary;
  }
  h3 {
    text-transform: uppercase;
    font-size: #{1.4em/$body-text-font-size}em;
  }
}

.home-block {
  width: 100%;
  max-width: 500px;
  &:not(:last-child) {
    margin-bottom: $spacer-xl;
  }
  @media(min-width: $single-column-threshold) {
    max-width: $home-block-row-max-width/3;
    margin-bottom: 0;
    &:not(:first-child) {
      padding-left: $spacer-xl;
    }
    &:not(:last-child) {
      padding-right: $spacer-xl;
      .home-block-dividers & {
        border-right: 1px solid #ccc;
      }
    }
  }
}

#welcome-strip,
#resources-overview {
  background-color: #fff;
  background-image: url("/theme/white-bg.png");
}

#welcome-strip .body-text {
  max-width: 450px;
}

#welcome-strip .body-text p,
#welcome-strip h2.welcome-strip-header {
  text-align: center
}

#news-events-overview {
  background-color: #767676;
  background-image: url("/theme/medium-grey-bg.png");
}

#news-events-overview > div {
 ul {
   background: #fff;
   padding: $spacer-md;
 }
}

// #news-events-overview div.block-outer div.block-inner {
//   margin-top: 0
// }

#news-events-overview h2 {
  text-align: center;
  color: #fff
}

#resources-overview {
  background-color: #fff;
  background-image: url("/theme/white-bg.png");
}

#networks-overview,
#member-schools-overview {
  background-color: #eee;
  background-image: url("/theme/light-grey-bg.png");
}

@media(min-width: $single-column-threshold) {
  #member-schools-overview {
    .home-block {
      max-width: $home-block-row-max-width/2;
    }
  }
}


div.featured-member-school  {
  @media(min-width: $single-column-threshold) {
    max-width: $home-block-row-max-width/3*2;
  }
  .member-school-card {
    width: 100%;
    max-width: 460px;
    margin: 0;
  }
}

.home-block.networks-links {
  max-width: 648px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  padding: 0;
  justify-content: center;
}

a.network-link {
  color: transparent;
  display: block;
  font-size: 0;
  height: 100px;
  width: 324px;
  background-image: url("/theme/networks-images.png");
  background-position: center top;
  background-repeat: no-repeat;
  margin-bottom: $spacer-lg;
  
}

a.network-link.gpean {
  background-position: center top
}

a.network-link.sdi {
  background-position: center -200px
}

a.network-link.glnt {
  background-position: center -300px
}

a.network-link.wiego {
  background-position: center -100px
}
